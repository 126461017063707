import { Body1, Card, CardHeader, CardPreview, Image, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import * as React from 'react'

const useStyles = makeStyles({
    headerContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    disclaimerText: {
        paddingLeft: "10px"
    },
    container: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexWrap: "wrap"
    },
    card: {
        width: "90%",
        maxWidth: "480px",        
        height: "fit-content",
        flexBasis: "auto",
        boxSizing: "border-box",
        backgroundColor: tokens.colorBrandBackground
    },
    cardPreview: {
        backgroundColor: tokens.colorNeutralBackground1,
        minHeight: "260px",
        ...shorthands.padding("15px")
    },
    imageContainer: {
        height: "200px !important"
    },
    descriptionContainer: {
        paddingTop: "15px"
    }
});

const Games: React.FC = () => {
    const styles = useStyles();

    return (
        <div>
            <div className={styles.headerContainer}><h1>Games</h1><div className={styles.disclaimerText}>(Subject to Change)</div></div>            
            <div className={styles.container}>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Bean Bag Toss</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/newBeanBagToss.png' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Toss bean bags at a group of buckets and get a prize based on how many bags you get in the buckets.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Bowling</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/bowling.jpg' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Bowl and get a prize based on if you get a strike, spare, or some pins.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Monster Muffin March</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/muffinMarch.png' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Walk around numbers in a circle to music. When the music stops, a random number is selected to win a muffin. Everyone else gets candy.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Duck Pond</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/duckPond.jpg' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Pick a duck and win a prize from the matching numbered bucket.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Fish Pond</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/fishPond.jpg' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Toss the "lure" over the curtain, wait for a "fish" to bite and reel in your prize.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Prize Drop</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/plinko.jpg' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Drop a puck down board to win a prize from the matching numbered bucket.</div>
                    </CardPreview>
                </Card>
                <Card className={styles.card}>
                    <CardHeader
                        header={
                            <Body1>
                                <b>Pumpkin Patch</b>
                            </Body1>
                        }/>
                    <CardPreview className={styles.cardPreview}>
                        <div className={styles.imageContainer}><Image src='/GameImages/pumpkinPatch.png' fit='contain'></Image></div>
                        <div className={styles.descriptionContainer}>Pick a pumpkin and win a prize from the matching numbered bucket.</div>
                    </CardPreview>
                </Card>
            </div>
        </div>
    );
};

export default Games;