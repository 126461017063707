import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import * as React from 'react'
import MicrosoftForm from './MicrosoftForm';

const useStyles = makeStyles({
    contentContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    welcomeContainer: {
        maxWidth: "75%",
        '@media (max-width: 600px)': {
            paddingBottom: "25px"
        }
    },
    cardContainer: {
        flexGrow: "1"
    },
/*     container: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexWrap: "wrap"
    }, */
    card: {
        width: "90%",
        maxWidth: "480px",
        height: "fit-content",
        flexBasis: "auto",
        boxSizing: "border-box",
        backgroundColor: tokens.colorBrandBackground
    },
    cardPreview: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding("15px")
    },
    buttonFooter: {
        paddingTop: "15px"
    }
});

const ContactUs: React.FC = () => {
    const styles = useStyles();

    return (
        <div className={styles.contentContainer}>
            <MicrosoftForm/>
        </div>
    );
};

export default ContactUs;