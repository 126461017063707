import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import React from 'react';
import { BrandVariants, FluentProvider, Theme, createDarkTheme } from '@fluentui/react-components';
import { BrowserRouter } from 'react-router-dom';

const halloweenCarnival: BrandVariants = { 
      10: "#050201",
      20: "#1F150B",
      30: "#342212",
      40: "#452C16",
      50: "#563719",
      60: "#68411D",
      70: "#7B4C20",
      80: "#8E5723",
      90: "#A26326",
      100: "#B66E29",
      110: "#CA7A2C",
      120: "#DF862E",
      130: "#F49231",
      140: "#FFA44D",
      150: "#FFBA7D",
      160: "#FFCFA6"
    };  
    
     const darkTheme: Theme = {
       ...createDarkTheme(halloweenCarnival), 
    };
     
     darkTheme.colorBrandBackground = halloweenCarnival[130];
     darkTheme.colorNeutralForeground2 = "#ffffff";//halloweenCarnival[160];
     darkTheme.colorBrandForeground1 = halloweenCarnival[110];
     darkTheme.colorBrandForeground2 = halloweenCarnival[120];

const container = document.getElementById('root');
if(container !== null) {
    const root = createRoot(container);
    root.render(
        <BrowserRouter>
            <FluentProvider theme={darkTheme}>
                <App />
            </FluentProvider>  
        </BrowserRouter>
    );
}
else {
    console.error("No element with id 'root' found.");
}

