import { Body1, Button, Card, CardHeader, CardPreview, makeStyles, shorthands, tokens, Tooltip } from '@fluentui/react-components';
import { CalendarAddFilled } from '@fluentui/react-icons';
import * as React from 'react'

const useStyles = makeStyles({
    contentContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    welcomeContainer: {
        maxWidth: "75%",
        '@media (max-width: 600px)': {
            paddingBottom: "25px"
        }
    },
    cardContainer: {
        flexGrow: "1"
    },
/*     container: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexWrap: "wrap"
    }, */
    card: {
        width: "90%",
        maxWidth: "480px",
        height: "fit-content",
        flexBasis: "auto",
        boxSizing: "border-box",
        backgroundColor: tokens.colorBrandBackground
    },
    cardPreview: {
        backgroundColor: tokens.colorNeutralBackground1,
        ...shorthands.padding("15px")
    },
    buttonFooter: {
        paddingTop: "15px"
    }
});

const Home: React.FC = () => {
    const styles = useStyles();

    const openUrl = (url: string) => {
        window.open(url, '_blank');
    };

    return (
        <div className={styles.contentContainer}>
            <div className={styles.welcomeContainer}>
                <h1>Welcome!</h1>
                <div>
                    For many years the Hermantown Halloween Carnival was put on by the PTO as a safe place for families to come on Halloween night for fun and games, and of course candy. After a ten year hiatus the Halloween Carnival is making a return for more fun, games and candy!
                </div>
            </div>
            <div className={styles.cardContainer}>
                <Card className={styles.card}>
                        <CardHeader
                            header={
                                <Body1>
                                    <b>Event Information</b>
                                </Body1>
                            }/>
                        <CardPreview className={styles.cardPreview}>
                            <div>
                                <strong>Date:</strong> October 31st, 2024 
                            </div>
                            <div>
                                <strong>Time:</strong> 4:00PM-6:00PM 
                            </div>
                            <div>
                                <strong>Location:</strong> Hermantown Middle School Gym
                            </div>
                            <div>
                                <strong>Cost:</strong> $1 per Game Ticket or $10 for 12 Game Tickets
                            </div>
                            <div className={styles.buttonFooter}>
                                <Tooltip content="Add to Your Calendar" relationship='description'>
                                    <Button icon={<CalendarAddFilled />} onClick={() => openUrl('/hermantown-halloween-carnival-2024.ics')} />
                                </Tooltip>
                            </div>
                        </CardPreview>
                </Card>
            </div>
        </div>
    );
};

export default Home;