import React from "react";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <footer style={{position: 'fixed', left: 0, bottom: 0, width: '100%', backgroundColor: '#333', color: '#fff', textAlign: 'center', padding: '10px 0'}}>
            © {year} Hermantown Halloween Carnival
        </footer>
    );
};

export default Footer;