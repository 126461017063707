import { Button, DrawerProps, makeStyles } from "@fluentui/react-components";
import * as React from "react"
import { FaBars } from "react-icons/fa6";
import NavigationDrawer from "./NavigationDrawer";

type NavigationMenuProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    position: DrawerProps["position"];
}

const useStyles = makeStyles({    
    button: {
      position: "absolute",
      left: "l0px",
      height: "50px",
      width: "50px",
      zIndex: "100"
    }
  });

const NavigationMenu: React.FC<NavigationMenuProps> = ({
    open,
    setOpen,
    position
}) => {
    const styles = useStyles();

    return (
        <div>
            <Button className={styles.button} appearance="primary" onClick={() => setOpen(!open)}>
                <FaBars />
            </Button>
            <NavigationDrawer 
            open={open}
            setOpen={setOpen}
            position={position}
            />
        </div>
    );
};

export default NavigationMenu;