import { makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';

const useStyles = makeStyles({
    contentContainer: {
        height: "60vh",
        width: "90%",
        maxWidth: "100%",
        maxHeight: "100vh",
        ...shorthands.border("none"),
    }
});

const MicrosoftForm: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.contentContainer}>
      <iframe width="100%"
       height="100%"
       title='Contact Us Form'
       src="https://forms.office.com/Pages/ResponsePage.aspx?id=DQSIkWdsW0yxEjajBLZtrQAAAAAAAAAAAAN__ofxeVRUMjNVVU00MkRBR0w0M04ySlBPVTVKM1JKNi4u&embed=true"
       allowFullScreen></iframe>
    </div>
  );
};

export default MicrosoftForm;
